<template>
  <div class="content">
    <div class="row">
      <div class="col mb-4 general-col">
        <GeneralInfoWidget :widget-visibility="widgetVisibility" :parameters="parameters" />
      </div>
      <div v-if="widgetVisibility[0]?.show" class="col mb-4 actions-col">
        <ActionsWidget />
      </div>
      <div v-if="widgetVisibility[1]?.show" class="col mb-4 calendar-col">
        <CalendarWidget />
      </div>
    </div>

    <IncidentsAndRisksWidget v-if="widgetVisibility[5]?.show" />
    <ProcessesGraphicWidget v-if="widgetVisibility[2]?.show" class="mb-4" />
    <TableWidget v-if="widgetVisibility[3]?.show" />
    <PivotParametersWidget v-if="widgetVisibility[4]?.show" />

    <ParametersWidget :parameters-visibility="parameters" @load-data="onLoadParameters" />
  </div>
</template>

<script>
import _ from 'lodash'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import GeneralInfoWidget from '@/components/pages/MainPage/widgets/GeneralInfoWidget'
import CalendarWidget from '@/components/pages/MainPage/widgets/CalendarWidget'
import StatisticWidget from '@/components/pages/MainPage/widgets/StatisticWidget'
import ProcessesGraphicWidget from '@/components/pages/MainPage/widgets/ProcessesGraphicWidget'
import TableWidget from '@/components/pages/MainPage/widgets/TableWidget'
import ActionsWidget from '@/components/pages/MainPage/widgets/ActionsWidget'
import ParametersWidget from '@/components/pages/MainPage/widgets/ParametersWidget'
import PivotParametersWidget from '@/components/pages/MainPage/widgets/PivotParametersWidget'
import IncidentsAndRisksWidget from '@/components/pages/MainPage/widgets/IncidentsAndRisksWidget'

export default {
  name: 'MainPage',
  components: {
    ParametersWidget,
    ActionsWidget,
    TableWidget,
    ProcessesGraphicWidget,
    StatisticWidget,
    CalendarWidget,
    GeneralInfoWidget,
    NextButton,
    PivotParametersWidget,
    IncidentsAndRisksWidget
  },
  data() {
    return {
      widgetVisibility: [
        {
          index: 1,
          show: true,
          name: 'Мероприятия, по статусу',
        },
        {
          index: 2,
          show: true,
          name: 'Календарь заседаний',
        },
        {
          index: 3,
          show: true,
          name: 'Уровень соответствий по разделам',
        },
        {
          index: 4,
          show: true,
          name: 'Сводная таблица результатов оценки',
        },
        {
          index: 5,
          show: true,
          name: 'Сводная таблица показателей Стационар',
        },
        {
          index: 6,
          show: true,
          name: '???',
        },
      ],
      parameters: [],
    }
  },
  watch: {
    widgetVisibility: {
      async handler(val) {
        // @TODO: в одну функцию перенести всё
        const template = JSON.parse(localStorage.user)?.template && JSON.parse(JSON.parse(localStorage.user)?.template)

        let newTemplate = {
          widgets: [],
          parameters: [],
        }

        if (template) {
          newTemplate = {
            ...template
          }
        }
        
        newTemplate.widgets = JSON.stringify(val)
        
        await this.$api.post('/users/set-template', {
          data: JSON.stringify(newTemplate)
        })
        await this.$store.dispatch('USER_GET')
      },
      deep: true
    },
    parameters: {
      async handler(val) {
        const template = JSON.parse(localStorage.user)?.template && JSON.parse(JSON.parse(localStorage.user)?.template)

        let newTemplate = {
          widgets: [],
          parameters: [],
        }

        if (template) {
          newTemplate = {
            ...template
          }
        }

        newTemplate.parameters = JSON.stringify(val)

        await this.$api.post('/users/set-template', {
          data: JSON.stringify(newTemplate)
        })
        await this.$store.dispatch('USER_GET')
      },
      deep: true
    },
  },
  mounted() {
    if (JSON.parse(localStorage.user)?.template) {
      const templateObject = JSON.parse(JSON.parse(localStorage.user)?.template)
      
      if (templateObject.widgets && typeof templateObject.widgets === 'string') {
        const diffArray = _.differenceBy(this.widgetVisibility, JSON.parse(templateObject.widgets), 'name')

        if (diffArray.length) {
          this.widgetVisibility = [
            ...JSON.parse(templateObject.widgets),
            ...diffArray,
          ]
        } else {
          this.widgetVisibility = JSON.parse(templateObject.widgets)
        }
      }
    }
  },
  methods: {
    onLoadParameters(data) {
      let parameters = data.map(item => {
        return {
          ...item,
          show: true,
        }
      })

      if (JSON.parse(localStorage.user)?.template) {
        const templateObject = JSON.parse(JSON.parse(localStorage.user)?.template)
        if (templateObject.parameters) {
          this.parameters = parameters.map((item) => {
            const array = typeof templateObject.parameters === 'string' ? JSON.parse(templateObject.parameters) : templateObject.parameters
            
            return {
              ...item,
              show: array.find((el) => el.id === item.id)?.show
            }
          })
        }
      } else {
        this.parameters = parameters
      }
    }
  },
}
</script>

<style lang="scss">
.general-col {
  min-width: 420px;
}

.actions-col {
  min-width: 420px;
}

.calendar-col {
}

.statistic-col {
  min-width: 420px;
  display: none;
  @media (max-width: 1752px) {
    display: block;
  }
}
</style>
