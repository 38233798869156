<template>
  <div class="card card-body overflow-auto mt-4">
    <h4 class="card-title mr-5 mb-4">Показатели по разделам ВКК</h4>
    <NSelect v-model:value="medtype" :options="medtypes" size="large" class="w-80 mb-4" clearable />
    <ParametersCharts
      :is-loading="isLoading"
      :charts-list="parametersChartsListWithVisibility"
      :button-click="(chart) => openModal(chart)"
    />
  </div>
</template>

<script>
import { NSelect } from 'naive-ui'
import moment from 'moment'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextIcon from '@/components/widgets/simple-components/icon'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import ParametersCharts from '@/components/common/ParametersCharts'

const quarter = ['1 квартал', '2 квартал', '3 квартал', '4 квартал']
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

export default {
  name: 'ParametersWidget',
  components: { NextTable, NextIcon, NextSelect, DonutChart, NextAlert, SpinLoader, ParametersCharts, NSelect },
  props: {
    parametersVisibility: {
      type: Array,
      default: () => []
    }
  },
  emits: ['load-data'],
  data() {
    return {
      isLoading: false,
      isError: false,
      parametersChartsList: [],
      signedProcessesList: [],
      signedProcessesIdsList: [],
      medtypes: [],
      medtype: undefined,
      options: {
        tooltip: {
          enabledOnSeries: [0],
          style: {
            fontSize: 18
          }
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        title: {
          align: 'center'
        },
        xaxis: {
          categories: months
        },
        yaxis: {
          forceNiceScale: true,
          min: 0,
          labels: {
            formatter: function (val) {
              const stringified = String(val)
              if (stringified.includes('.') && stringified.split('.')[1].length > 1) {
                return val.toFixed(0)
              }
              if (val) {
                return val.toFixed(0)
              }
              return val
            }
          }
        },
        colors: ['#31AF92', '#0284c7'],
        plotOptions: {
          bar: {
            columnWidth: '40%'
          }
        },
        stroke: {
          width: [0, 3]
        }
      }
    }
  },
  computed: {
    parametersChartsListWithVisibility() {
      const visibilityArr = this.parametersVisibility.map(item => {
        if (item.show) {
          return item.id
        } else {
          return null
        }
      }).filter(item => Boolean(item)) || []
      
      return this.parametersChartsList.filter((item) => visibilityArr.includes(item.id))
    }
  },
  watch: {
    medtype() {
      this.parametersChartsList = []
      this.fetchProcesses()
    }
  },
  mounted() {
    this.fetchProcesses()
    this.fetchMedtypes()
  },
  methods: {
    async fetchMedtypes() {
      const medtypesResponse = await this.$api.get('/organization/get-organization-medtypes')
      if (medtypesResponse.data.ok === 1 && medtypesResponse.data.data.length) {
        this.medtypes = medtypesResponse.data.data.map((medtype) => ({
          ...medtype,
          value: medtype.id,
          label: medtype.text
        }))
      }
    },
    async fetchAndNormalizeIndicators() {
      const indicatorsPromisesForEachProcessId = this.signedProcessesIdsList.map((process_id) => {
        return this.$api.post('/process/get-indicators', { process_id, year: new Date().getFullYear() })
      })

      let responseIndicatorsForEachProcessId = await Promise.all(indicatorsPromisesForEachProcessId)

      responseIndicatorsForEachProcessId = responseIndicatorsForEachProcessId
        .map((responseObject) => responseObject.data.data)
        .filter((responseData) => responseData && responseData.length)

      const indicators = responseIndicatorsForEachProcessId.reduce((accum, arrayOfIndicators) => {
        return [...accum, ...arrayOfIndicators]
      }, [])

      const indicatorsWithCalendarValues = await this.fetchDailyIndicators(indicators)

      return indicatorsWithCalendarValues || []
    },
    async getParameters() {
      this.isLoading = true

      let {
        data: { data: parameters, ok }
      } = await this.$api.post('/process/get-parameters', {
        process_id: this.signedProcessesIdsList,
        active: 1
      })

      parameters = parameters.map((parameter) => ({
        ...parameter,
        min: Number(parameter.min),
        max: Number(parameter.max),
      }))

      if (ok === 1) {
        const indicators = await this.fetchAndNormalizeIndicators()

        const filledParameters = parameters
          .map((parameter) => {
            try {
              const matchedIndicator = indicators.find((indicator) => parameter.process_id === indicator.process_id && indicator.process_indicator_id === parameter.id)

              const autoFillArray = (array = []) => {
                const arr = [...array]

                while (arr.length < 12) {
                  arr.push(null)
                }
                return arr
              }

              let values =
                matchedIndicator?.values.length === 12
                  ? matchedIndicator?.values
                  : autoFillArray(matchedIndicator?.values)

              if (matchedIndicator?.view_mode === 2) {
                values = matchedIndicator?.values
              }

              if (matchedIndicator?.view_mode === 3) {
                let arr = []
                
                Object.keys(matchedIndicator?.calendar_values)
                  .sort()
                  .map((key) => {
                    arr.push(matchedIndicator?.calendar_values[key])
                  })

                values = arr
              }

              const { medtype } = this.signedProcessesList.find((process) => process.id === parameter.process_id)
              
              const chart = {
                ...parameter,
                values,
                medtype,
                options: this.getCustomOptions({ ...parameter, medtype, ...matchedIndicator }),
                series: this.getCustomSeries({ ...parameter, values }, matchedIndicator),
                view_mode: matchedIndicator?.view_mode,
                calendar_values: matchedIndicator?.calendar_values || {},
              }

              return chart
            } catch (error) {
              console.error('Error in map:', error);

            }
            
          })
          .filter(
            (parametersObject) =>
              parametersObject.values.length && !parametersObject.values.every((value) => value === null)
          )

        this.parametersChartsList = filledParameters
        this.$emit('load-data', filledParameters)
      }
    },
    async fetchProcesses() {
      try {
        this.isLoading = true

        const result = await this.$api.get('process/get-signed-processes', {
          params: {
            medtype: this.medtype,
            limit: 99999
          }
        })

        if (result.data.ok === 1) {
          this.signedProcessesIdsList = result.data.data.map((process) => process.id)
          this.signedProcessesList = result.data.data

          await this.getParameters()
        } else {
          // this.error = result.data.message
        }
      } catch (e) {
        // this.error = e.message
      } finally {
        this.isLoading = false
      }
    },
    async fetchDailyIndicators(indicators) {
      const promises = indicators.map(async (indicator) => {
        
        const params = {
          process_id: indicator.process_id,
          year: indicator.year,
          indicator_id: indicator.process_indicator_id
        }

        const res = await this.$api.post('/process/get-daily-indicators', params)

        return {
          ...indicator,
          calendar_values: res.data.data
        }
      })

      const values = await Promise.all(promises)
      return values      
    },
    getCustomOptions(chart) {
      // const suffix = this.getSuffix(chart.input_type)

      const options = {
        ...this.options,
        title: {
          ...this.options.title
          // text: `${chart.name}${suffix} (${chart.medtype.text})`
        },
        yaxis: {
          ...this.options.yaxis,
          min: chart.min,
        }
      }

      // tickAmount по 10 ставится только если хоть одно значение >= 10, иначе значения в оси дублируются
      if (chart.values?.some((value) => value >= 10)) {
        options.yaxis.tickAmount = 10
      }

      // max задаётся только для процентных значений
      if (chart.input_type === 2) {
        options.yaxis.max = chart.max

        if (chart.max % 10 > 0) {
          options.yaxis.max = (Math.ceil(chart.max / 10) * 10)
          options.yaxis.tickAmount = (Math.ceil(chart.max / 10) * 10) / 10
        }

        options.yaxis.forceNiceScale = false
      }
      
      if (chart.view_mode === 2) {
        options.xaxis = {
          ...options.xaxis, 
          categories: quarter
        }
      }
      
      if (chart.view_mode === 3 && Object.keys(chart.calendar_values).length) {
        options.xaxis = {
          ...options.xaxis, 
          categories: Object.keys(chart?.calendar_values).sort().map((date) => moment(date).format('DD.MM.YYYY'))
        }
      }

      return options
    },
    getCustomSeries(chart, matchedIndicator) {
      // const trendLineParams = this.calculateTrendLine(chart.values)
      // const trendLineData = this.generateTrendLinePoints(chart.values, trendLineParams, chart.min, chart.max)
      let trendLineParams
      let trendLineData

      if (matchedIndicator?.view_mode === 3) {
        const values = 
          Object
            .keys(matchedIndicator.calendar_values)
            .sort()
            .map(key => matchedIndicator.calendar_values[key])
            .map((v) => (v === '' || v === null ? null : Number(v)))

        trendLineParams = this.calculateTrendLine(values)
        trendLineData = this.generateTrendLinePoints(values, trendLineParams, chart.min, chart.max)
      } else {
        trendLineParams = this.calculateTrendLine(chart.values)
        trendLineData = this.generateTrendLinePoints(chart.values, trendLineParams, chart.min, chart.max)
      }

      const series = [
        {
          type: 'bar',
          name: chart.name,
          data: chart.values
        }
      ]

      if (chart.values.length > 1 && !chart.values.every((v) => v === null)) {
        series.push({
          type: 'line',
          name: 'Тренд',
          data: trendLineData
        })
      }

      return series
    },
    calculateTrendLine(data) {
      let xSum = 0,
        ySum = 0,
        xySum = 0,
        xxSum = 0
      let validCount = 0 // Счетчик для подсчета количества неигнорируемых элементов

      for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) {
          // Проверяем, не равен ли текущий элемент null
          xSum += validCount // Используем validCount вместо i для расчета сумм
          ySum += data[i]
          xySum += validCount * data[i]
          xxSum += validCount * validCount
          validCount++ // Увеличиваем счетчик для каждого неигнорируемого элемента
        }
      }

      if (validCount === 0) {
        // Если все значения были null, возвращаем m и b как NaN или другое значимое значение
        return { m: NaN, b: NaN }
      }

      const m = (validCount * xySum - xSum * ySum) / (validCount * xxSum - xSum * xSum)
      const b = (ySum - m * xSum) / validCount

      return { m, b }
    },
    generateTrendLinePoints(data, { m, b }, min, max) {
      let trendLine = data.map((_, index) => Number((m * index + b).toFixed(5)))
      
      // Если max undefined, используем текущие минимальные и максимальные значения трендовой линии
      if (!max) {
        max = Math.max(...trendLine);
      }

      const scaledValues = this.scaleValuesWithinRange(trendLine, min, max);
      
      return scaledValues;
    },
    scaleValuesWithinRange(values, min, max) {
      const originalMin = Math.min(...values);
      const originalMax = Math.max(...values);

      // Если все значения уже в пределах min и max, ничего делать не нужно
      if (originalMin >= min && originalMax <= max) {
        return values;
      }

      // Масштабируем значения
      const scaledValues = values.map((value) => {
        // Пропорционально адаптируем значение к новому диапазону
        const scaledValue = ((value - originalMin) / (originalMax - originalMin)) * (max - min) + min;
        return scaledValue;
      });

      return scaledValues;
    }
  }
}
</script>
