<template>
  <div class="content-block">
    <div class="content-header">
      Заседание {{ headerText }}
      <div class="content-manage">
        <div class="next-button">
          <nextButton text="Создать" @click-handler="createInspection" />
        </div>
        <nextButton color="white" text="Отменить" @click-handler="goBack" />
      </div>
    </div>

    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
    </div>

    <div class="main-block tab-content">
      <div v-if="currentTab === 'main'">
        <div class="extra-settings">
          <div class="settings-item settings__date-picker">
            <span>Дата заседания</span>
            <NDatePicker
              v-model:value="formValue.datetime"
              style="width: 280px"
              format="dd.MM.yyyy"
              aria-required
              type="date"
              size="large"
            />
          </div>

          <div class="settings-item">
            <span class="settings-item-label">Очередность</span>
            <NSelect
              v-model:value="formValue.queue_id"
              style="width: 280px"
              :options="inspectionQueueList"
              size="large"
            />
          </div>
        </div>
        <div class="extra-settings">
          <div class="settings-item">
            <span class="settings-item-label">{{ chairmanTitle }}</span>
            <NSelect
              v-model:value="formValue.chairman_id"
              style="width: 280px"
              :options="responsibleUsers"
              size="large"
              clearable
            />
          </div>

          <div v-if="showDeputy" class="settings-item">
            <span class="settings-item-label">Заместитель</span>
            <NSelect
              v-model:value="formValue.deputy_id"
              style="width: 280px"
              :options="responsibleUsers"
              size="large"
              clearable
            />
          </div>

          <div class="settings-item">
            <span class="settings-item-label">Секретарь</span>
            <NSelect
              v-model:value="formValue.secretary_id"
              style="width: 280px"
              :options="responsibleUsers"
              size="large"
              clearable
            />
          </div>

          <div class="settings-item">
            <span class="settings-item-label">{{ membersTitle }}</span>
            <NSelect
              v-model:value="formValue.members"
              style="width: 280px"
              multiple
              :options="responsibleUsers"
              size="large"
              clearable
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { NDatePicker, NSelect, NInput, NButton, NH3 } from 'naive-ui'
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import moment from 'moment'

export default {
  components: {
    checkbox,
    dropdownListWithHeader,
    nextButton,
    inputWithHeader,
    NDatePicker,
    NSelect,
    NInput,
    NButton,
    NH3
  },
  data() {
    return {
      responsibleUsers: [],
      currentTab: 'main',
      formValue: ref({
        datetime: new Date().getTime(),
        queue_id: '',
        chairman_id: '',
        deputy_id: '',
        secretary_id: '',
        members: []
      })
    }
  },
  computed: {
    headerText() {
      const { type } = this.$router.currentRoute.value.query
      let text = 'врачебной комиссии'

      if (type == 2) {
        text = 'комиссии по внутреннему контролю (ВКК)'
      }

      if (type == 3) {
        text = 'рабочей группы'
      }

      return text
    },
    inspectionQueueList() {
      const transformedQueueList = this.$store.getters.inspectionQueueList.map((queue) => ({
        ...queue,
        label: queue.name,
        value: queue.id
      }))

      return transformedQueueList
    },
    showDeputy() {
      return ['1', '2'].includes(this.$router.currentRoute.value.query.type)
    },
    chairmanTitle() {
      if (['1', '2'].includes(this.$router.currentRoute.value.query.type)) {
        return 'Председатель комиссии'
      } else {
        return 'Руководитель рабочей группы'
      }
    },
    membersTitle() {
      if (['1', '2'].includes(this.$router.currentRoute.value.query.type)) {
        return 'Члены комиссии'
      } else {
        return 'Участники заседания'
      }
    }
  },
  async mounted() {
    this.getResponsibleUsers()
    this.getInspectionQueueList()
  },
  async unmounted() {
    this.$store.dispatch('REQUIREMENT_CLEAR')
  },
  methods: {
    getInspectionQueueList() {
      this.$store.dispatch('INSPECTION_SET_QUEUE_LIST', {})
    },
    async getResponsibleUsers() {
      const { type: inspectionType } = this.$router.currentRoute.value.query
      const filterConditionMap = {
        1: (user) => user.med_commission == 1,
        2: (user) => user.vkk == 1,
        3: (user) => user
      }
      const filterCb = filterConditionMap[inspectionType]

      try {
        const res = await this.$api.get('responsible/get')

        if (res.data.ok === 1) {
          let users = res.data.data.filter(filterCb).map((user) => ({
            ...user,
            value: user.id,
            label: user.name
          }))

          this.responsibleUsers = users
        }
      } catch (error) {
        console.warn('getResponsibleUsers', error)
      }
    },
    changeTab(tab) {
      this.currentTab = tab
    },

    //////////////////////////////

    goBack() {
      this.$router.back()
    },
    async createInspection() {
      const payload = {
        type_id: this.$router.currentRoute.value.query.type,
        datetime: moment(this.formValue.datetime).format().split('+')[0].replace('T', ' '),
        queue_id: String(this.formValue.queue_id),
        chairman_id: String(this.formValue.chairman_id),
        deputy_id: String(this.formValue.deputy_id),
        secretary_id: String(this.formValue.secretary_id),
        members: this.formValue.members
      }

      try {
        const { status, data } = await this.$store.dispatch('INSPECTION_CREATE', payload)

        if (status === 200 && !data.errors) {
          this.$router.push(`/inspection/${data.id}`)
        }
      } catch (err) {
        console.warn('INSPECTION_CREATE:', err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.settings-item {
  min-width: 200px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.settings-item-label {
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 9px;
  display: block;
}

.extra-settings {
  display: flex;
  flex-flow: row wrap;
}
.settings__date-picker span {
  font-size: 14px;
  margin-bottom: 7px;
  display: block;

  strong {
    color: #1cc8ab;
  }
}
.input-block {
  width: 100%;
}
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.content-info {
  position: relative;
  z-index: 2;
}
.content-manage {
  display: flex;
}
.red-christ-icon {
  position: absolute;
  right: 4px;
  top: 7px;
}
.med-item__text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  max-width: 300px;
  padding-right: 10px;
}
.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  max-width: 300px;
  position: relative;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.select-header {
  padding-left: 30px;
  padding-right: 60px;
}
.next-button {
  margin-right: 30px;
}
.main-block {
  background-color: white;
  padding: 23px 40px;
}
</style>
