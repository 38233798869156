<template>
  <div class="tariff-container">
    <div v-for="({ name, price, info, id }, index) in tariffsNames" :key="name" class="tariff-card">
      <div class="flex justify-content-between">
        <h4 class="mb-2 font-weight-bold">{{ name }}</h4>
        <NButton type="primary" class="mr-4" @click="toggleTariff({ name, id })">Выбрать тариф</NButton>
      </div>

      <div class="price mb-4">
        <span class="price-value">{{ price }}</span>

        <NPopover v-if="index === 0" placement="right">
          <template #trigger>
            <NIcon size="20" class="mr-2">
              <Help />
            </NIcon>
          </template>
          <div class="p-4" style="width: 600px">
            <p class="text-center">*Акция. На тарифе «Стартовый» вы сможете:</p>
            <p class="mb-3">
              - получить пакет шаблонов необходимых локальных актов по разделу «Управление внутреннего контроля качества
              и безопасности медицинской деятельности»;
            </p>
            <p class="mb-3">
              - проводить внутренние проверки (аудиты) по основному функциональному блоку «Управление внутреннего
              контроля качества и безопасности медицинской деятельности»;
            </p>
            <p class="mb-3">
              - формировать в автоматизированном режиме «Отчеты по результатам проведенных аудитов» и «Протоколы
              заседаний Комиссии ВКК/рабочих групп»;
            </p>
            <p class="mb-3">
              - создавать и отслеживать выполнение корректирующих и предупреждающих мероприятия по устранению
              несоответствий, выявленных по результатам проведенных внутренних проверок (аудитов);
            </p>
            <p class="mb-3">
              - научиться работать с аналитикой и статистикой системы управления качеством и безопасностью медицинской
              деятельности;
            </p>
            <p class="mb-3">- освоить возможности автоматизации документооборота в системе «ВКК+БМД».</p>
            <b
              >Срок действия бесплатного тарифа «Стартовый» 30 дней с даты регистрации в системе «ВКК+БМД». По
              прошествии этого срока вам необходимо будет перейти на любой из других тарифов. Если переход не
              осуществится, доступ к приложениям системы будет закрыт.</b
            >
          </div>
        </NPopover>
      </div>

      <div class="info mb-3">
        <p v-for="{ text, disabled } in info" :key="text" class="mb-1">
          <NIcon size="18" class="mr-2">
            <X v-if="disabled" color="#b0bbc6" />
            <CircleCheck v-else color="#37c4a3" />
          </NIcon>
          <span>{{ text }}</span>
        </p>
      </div>
    </div>
  </div>

  <NModal :show="Boolean(tariffModal.id)" @positive-click="submitCallback" @negative-click="cancelCallback">
    <NCard style="width: 600px" title="Подтвердите выбор" :bordered="false" role="dialog" aria-modal="true">
      <template #header-extra>
        <NButton :bordered="false" size="tiny" @click="toggleTariff({})">
          <NIcon size="16"><X /></NIcon>
        </NButton>
      </template>

      <template #default>
        <h6>{{ tariffModal.name }}</h6>
      </template>

      <template #action>
        <div class="flex justify-content-end"><NButton @click="onTarifSubmit" type="primary">Подтвердить</NButton></div>
      </template>
    </NCard>
  </NModal>
</template>

<script setup>
import { ref } from 'vue'
import { NIcon, NButton, NModal, NCard, NPopover, useNotification } from 'naive-ui'
import { CircleCheck, X, Help } from '@vicons/tabler'
import api from '@/api'

const notify = useNotification()

const tariffsNames = [
  {
    id: 2,
    name: 'Тариф "Стартовый"',
    price: 'Бесплатно *',
    info: [
      { text: 'Количество подключенных организаций - 1', disabled: false },
      { text: 'Количество рабочих мест - без ограничений', disabled: false },
      { text: 'Модуль внутренних проверок (стартовый)', disabled: false },
      { text: 'Внедрение Практических рекомендаций Росздравнадзора', disabled: true },
      { text: 'Пакет шаблонов документации по организации ВКК и БМД', disabled: false },
      { text: 'Управление Мероприятиями', disabled: false },
      { text: 'Система push и email оповещений', disabled: false },
      { text: 'Управление несоответствиями', disabled: false },
      { text: 'Управление Заседаниями', disabled: true },
      { text: 'Управление нежелательными (неблагоприятными) событиями', disabled: true },
      { text: 'Управление рисками', disabled: true },
      { text: 'Формирование статистических показателей', disabled: true },
      { text: 'Техническая поддержка', disabled: false },
      { text: 'Автоматизация документооборота (хранение, просмотр, печать)', disabled: false },
      { text: 'Облачное хранилище до 2 Гб', disabled: false }
    ]
  },
  {
    id: 3,
    name: 'Тариф "Стандарт"',
    price: 'По запросу',
    info: [
      { text: 'Количество подключенных организаций - 1', disabled: false },
      { text: 'Количество рабочих мест - без ограничений', disabled: false },
      { text: 'Модуль внутренних проверок', disabled: false },
      { text: 'Внедрение Практических рекомендаций Росздравнадзора', disabled: false },
      { text: 'Пакет шаблонов документации по организации ВКК и БМД', disabled: false },
      { text: 'Управление Мероприятиями', disabled: false },
      { text: 'Система push и email оповещений', disabled: false },
      { text: 'Управление несоответствиями', disabled: false },
      { text: 'Управление Заседаниями', disabled: true },
      { text: 'Управление нежелательными (неблагоприятными) событиями', disabled: true },
      { text: 'Управление рисками', disabled: true },
      { text: 'Формирование статистических показателей', disabled: false },
      { text: 'Техническая поддержка', disabled: false },
      { text: 'Автоматизация документооборота (хранение, просмотр, печать)', disabled: false },
      { text: 'Облачное хранилище до 30 Гб', disabled: false }
    ]
  },
  {
    id: 4,
    name: 'Тариф "Премиум"',
    price: 'По запросу',
    info: [
      { text: 'Количество подключенных организаций - 1', disabled: false },
      { text: 'Количество рабочих мест - без ограничений', disabled: false },
      { text: 'Модуль внутренних проверок', disabled: false },
      { text: 'Внедрение Практических рекомендаций Росздравнадзора', disabled: false },
      { text: 'Пакет шаблонов документации по организации ВКК и БМД', disabled: false },
      { text: 'Управление Мероприятиями', disabled: false },
      { text: 'Система push и email оповещений', disabled: false },
      { text: 'Управление несоответствиями', disabled: false },
      { text: 'Управление Заседаниями', disabled: false },
      { text: 'Управление нежелательными (неблагоприятными) событиями', disabled: false },
      { text: 'Управление рисками', disabled: false },
      { text: 'Формирование статистических показателей', disabled: false },
      { text: 'Техническая поддержка', disabled: false },
      { text: 'Автоматизация документооборота (хранение, просмотр, печать)', disabled: false },
      { text: 'Облачное хранилище до 1 TБ', disabled: false }
    ]
  }
]

const tariffModal = ref({})

const toggleTariff = (tariff) => {
  tariffModal.value = tariff
}

const onTarifSubmit = async () => {
  try {
    const res = await api.post(`/organization/choose-tariff?id=${tariffModal.value.id}`)

    if (res.data.ok === 1) {
      toggleTariff({})
      notify.success({
        title: 'Запрос на смену тарифа отправлен',
        duration: 2500
      })
    }
  } catch (error) {
    notify.error({
      title: 'Произошла ошибка',
      content: `Попробуйте повторить`,
      duration: 2500
    })
  }
}
</script>

<style scoped>
.tariff-container {
  gap: 20px;
  display: flex;
}
.tariff-card {
  width: 33%;
  padding: 20px;
}

.price-value {
  color: #37c489;
  font-weight: 600;
  margin-right: 8px;
  font-size: 18px;
}
.price-symbol {
  color: #b0bbc6;
}

.info span {
  font-size: 13px;
}
</style>
