<template>
  <div class="content-block pt-0">
    <div class="content-header">Данные об организации:</div>
    <div class="content-info">
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.name"
          header="Медицинская организация:"
          :required="true"
          placeholder="Введите название организации"
          :autocomplete="false"
          @changeHandler="nameInput"
          @inputHandler="nameInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.director"
          header="ФИО руководителя:"
          :required="false"
          placeholder="Введите ФИО директора"
          :autocomplete="false"
          @changeHandler="directorInput"
          @inputHandler="directorInput"
        />
      </div>
      <!-- <div class="information-item">
        <inputWithHeader
          :start-text="organization.quality"
          :required="false"
          :autocomplete="false"
          type="text"
          header="Уполномоченный по качеству:"
          placeholder="ФИО уполномоченного по качеству"
          @changeHandler="qualityInput"
          @inputHandler="qualityInput"
        />
      </div> -->
    </div>
  </div>
  <div class="content-block">
    <div class="content-header">Сведения о юридическом лице:</div>
    <div class="content-info">
      <div class="information-item">
        <inputWithHeader
          type="number"
          :start-text="organization.inn"
          header="ИНН:"
          :required="true"
          placeholder="Введите ИНН организации"
          :autocomplete="false"
          disabled
          @changeHandler="INNInput"
          @inputHandler="INNInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="number"
          :start-text="organization.kpp"
          header="КПП:"
          :required="true"
          placeholder="Введите КПП организации"
          :autocomplete="false"
          @changeHandler="KPPInput"
          @inputHandler="KPPInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="number"
          :start-text="organization.ogrn"
          header="ОГРН:"
          :required="true"
          placeholder="Введите ОГРН организации"
          :autocomplete="false"
          @changeHandler="OGRNInput"
          @inputHandler="OGRNInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.address"
          header="Юридический адрес:"
          :required="true"
          placeholder="Введите юридический адрес"
          :autocomplete="false"
          @changeHandler="addressInput"
          @inputHandler="addressInput"
        />
      </div>
    </div>
  </div>
  <div class="content-block">
    <div class="content-header">Контактная информация:</div>
    <div class="content-info">
      <div class="information-item">
        <inputWithHeader
          :start-text="organization.phone"
          header="Телефон:"
          :type="'tel'"
          :required="true"
          placeholder="+7 (___) ___-__-__"
          :autocomplete="false"
          @changeHandler="phoneInput"
          @inputHandler="phoneInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="tel"
          :start-text="organization.fax"
          header="Факс:"
          :required="false"
          placeholder="+7 (___) ___-__-__"
          :autocomplete="false"
          @changeHandler="faxInput"
          @inputHandler="faxInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.email"
          header="Электронная почта:"
          :required="true"
          placeholder="Введите E-mail организации"
          :autocomplete="false"
          @changeHandler="emailInput"
          @inputHandler="emailInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.site"
          header="Сайт:"
          :required="false"
          placeholder="Введите сайт организации"
          :autocomplete="false"
          @changeHandler="siteInput"
          @inputHandler="siteInput"
        />
      </div>
      <div class="information-item">
        <inputWithHeader
          type="text"
          :start-text="organization.mail_address"
          header="Почтовый адрес:"
          :required="true"
          placeholder="Введите почтовый адрес"
          :autocomplete="false"
          @changeHandler="postAddressInput"
          @inputHandler="postAddressInput"
        />
      </div>
    </div>
  </div>
  <!-- <div class="content-block">
    <div class="content-header">Вид медицинской помощи:</div>
    <div class="content-info">
      <dropdownListWithHeader
        header="Добавьте виды медицинской помощи"
        placeholder="Выберите из списка"
        :list="medhelps"
        @inputHandler="searchMedHelpsInput"
        @enterHandler="searchMedHelpsEnter"
        @openDropdownHandler="updateMedHelps"
        @selectHandler="medHelpSelect"
      />
    </div>
    <div class="content-list">
      <div v-for="(item, key) in selectedMedHelps" :key="key" class="med-item">
        {{ item.text }}
        <span class="icon red-christ-icon pointer" @click="removeMedHelp(item)" />
      </div>
    </div>
  </div> -->
  <!-- <div class="content-block border-0">
    <div class="content-header">Вид медицинской деятельности:</div>
    <div class="content-info">
      <dropdownListWithHeader
        header="Добавьте виды медицинской помощи"
        placeholder="Выберите из списка"
        :list="medorgs"
        @inputHandler="searchMedActionsInput"
        @enterHandler="searchMedActionsEnter"
        @openDropdownHandler="updateMedActions"
        @selectHandler="medActionsSelect"
      />
    </div>
    <div class="content-list">
      <div v-for="(item, key) in selectedMedActions" :key="key" class="med-item">
        {{ item.text }}
        <span class="icon red-christ-icon pointer" @click="removeMedAction(item)" />
      </div>
    </div>
  </div> -->
  <div class="no-content-block buttons-container">
    <div class="left-side">
      <nextButton
        :color="'green'"
        :title="createValidTitle"
        :class="{ inactive: !checkValid }"
        text="Сохранить"
        @click-handler="saveOrganization"
      />
      <div class="left-side__text">
        <span style="color: #1cc8ab">*</span>
        <span>Поля, помеченные звёздочкой, являются обязательными для заполнения.</span>
      </div>
    </div>
    <nextButton color="white" type="close" text="Отменить" @clickHandler="editClose" />
  </div>
</template>

<script>
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'

export default {
  name: 'MainTabEdit',
  components: {
    inputWithHeader,
    nextButton,
    dropdownListWithHeader
  },
  emits: ['cancel-handle'],
  data() {
    return {
      currentOrganization: {
        creator_id: '',
        director: '',
        // quality: '',
        id: '',
        name: '',
        inn: '',
        kpp: '',
        address: '',
        ogrn: '',
        mail_address: '',
        phone: '',
        email: '',
        site: '',
        fax: ''
      },
      selectedMedHelps: [],
      selectedMedActions: []
    }
  },
  computed: {
    medorgs() {
      let result = []
      for (let i = 0; i < this.$store.getters['allMedactions'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedMedActions.length; n++) {
          if (this.$store.getters['allMedactions'][i].id.toString() === this.selectedMedActions[n].id.toString()) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['allMedactions'][i])
        }
      }
      return result
    },
    medhelps() {
      let result = []
      for (let i = 0; i < this.$store.getters['allMedhelps'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedMedHelps.length; n++) {
          if (this.$store.getters['allMedhelps'][i].id.toString() === this.selectedMedHelps[n].id.toString()) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['allMedhelps'][i])
        }
      }
      return result
    },
    organization() {
      const currentOrganization = this.$store.getters['currentOrganization'] || JSON.parse(localStorage.user)?.organization
      if (currentOrganization) {
        return currentOrganization
      } else {
        return this.currentOrganization
      }
    },
    checkValid() {
      let notEmpty = [
        this.organization.name,
        this.organization.inn,
        this.organization.kpp,
        this.organization.address,
        this.organization.ogrn,
        this.organization.phone,
        this.organization.email
      ]
      for (let i = 0; i < notEmpty.length; i++) {
        if (notEmpty[i] === '') {
          return false
        }
      }
      return true
    },
    createValidTitle() {
      let text = ''
      if (this.organization.name === '') {
        text += 'Введите название организации. '
      }
      if (this.organization.inn === '') {
        text += 'Введите ИНН организации. '
      }
      if (this.organization.kpp === '') {
        text += 'Введите КПП организации. '
      }
      if (this.organization.ogrn === '') {
        text += 'Введите ОГРН организации. '
      }
      if (this.organization.address === '') {
        text += 'Введите юридический адресс организации. '
      }
      if (this.organization.legalAddress === '') {
        text += 'Введите почтовый адресс организации. '
      }
      if (this.organization.phone === '') {
        text += 'Введите телефон организации. '
      }
      if (this.organization.email === '') {
        text += 'Введите электронную почту организации. '
      }
      return text
    }
  },
  mounted() {
    this.getMedHelps()
    this.getMedActions()
  },
  methods: {
    async getMedActions() {
      let result = await this.$api.get(
        'organization/get-organization-medactions?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectedMedActions = result.data.data
    },
    async getMedHelps() {
      let result = await this.$api.get(
        'organization/get-organization-medhelps?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectedMedHelps = result.data.data
    },
    async searchMedActionsInput(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('ORGANIZATION_SEARCH_MEDACTIONS', val)
      }, 500)
    },
    async searchMedActionsEnter(val) {
      this.$store.dispatch('ORGANIZATION_SEARCH_MEDACTIONS', val)
    },
    updateMedActions() {
      this.$store.dispatch('ORGANIZATION_GET_ALL_MEDACTIONS')
    },
    removeMedAction(item) {
      for (let i = 0; i < this.selectedMedActions.length; i++) {
        if (this.selectedMedActions[i].id.toString() === item.id.toString()) {
          this.selectedMedActions.splice(i, 1)
        }
      }
    },
    medActionsSelect(val) {
      let item = this.$store.getters['allMedactions'].find((item) => item.id.toString() === val.id.toString())
      this.selectedMedActions.push(item)
    },
    async searchMedHelpsInput(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('ORGANIZATION_SEARCH_MEDHELPS', val)
      }, 500)
    },
    async searchMedHelpsEnter(val) {
      this.$store.dispatch('ORGANIZATION_SEARCH_MEDHELPS', val)
    },
    updateMedHelps() {
      this.$store.dispatch('ORGANIZATION_GET_ALL_MEDHELPS')
    },
    removeMedHelp(item) {
      for (let i = 0; i < this.selectedMedHelps.length; i++) {
        if (this.selectedMedHelps[i].id.toString() === item.id.toString()) {
          this.selectedMedHelps.splice(i, 1)
        }
      }
    },
    medHelpSelect(val) {
      let item = this.$store.getters['allMedhelps'].find((item) => item.id.toString() === val.id.toString())
      this.selectedMedHelps.push(item)
    },
    editClose() {
      this.$emit('cancel-handle')
    },
    saveOrganization() {
      this.organization.creator_id = this.$store.getters.currentUser.id
      this.organization.medOrgs = this.selectedMedActions
      this.organization.medHelps = this.selectedMedHelps

      this.$store.dispatch('ORGANIZATION_SAVE_ORGANIZATION', this.organization).then((res) => {
        if (res.data.ok === 1) {
          this.$store.dispatch('ORGANIZATION_ADD', res.data.organization).then(() => {
            this.editClose()
          })
        } else {
          this.createErrors(res.data.errors)
        }
      })
    },
    createErrors(errors) {
      console.log(errors)
    },
    postAddressInput(val) {
      this.organization.mail_address = val
    },
    directorInput(val) {
      console.log(val)
      this.organization.director = val
    },
    // qualityInput(val) {
    //   this.organization.quality = val
    // },
    siteInput(val) {
      this.organization.site = val
    },
    emailInput(val) {
      this.organization.email = val
    },
    faxInput(val) {
      this.organization.fax = val
    },
    phoneInput(val) {
      this.organization.phone = val
    },
    addressInput(val) {
      this.organization.address = val
    },
    OGRNInput(val) {
      this.organization.ogrn = val
    },
    KPPInput(val) {
      this.organization.kpp = val
    },
    INNInput(val) {
      this.organization.inn = val
    },
    nameInput(val) {
      this.organization.name = val
    }
  }
}
</script>

<style scoped>
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.left-side {
  display: flex;
}
.left-side__text span {
  display: inline-block;
  margin-right: 10px;
}
.left-side__text {
  font-size: 14px;
  line-height: 19px;
  width: 250px;
  display: flex;
  margin-left: 60px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
}
.content-block {
  border-bottom: 1px solid #cccccc;
  padding: 24px 0;
}
.content-header {
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 23px;
}
.information-icon {
  flex: none;
}
.information-item {
  padding-bottom: 40px;
  margin-right: 30px;
}

.information-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  background-position: center;
}

.information-description {
  padding-left: 20px;
}

.information-item {
  width: 308px;
}

.information-text {
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.content-info {
  display: flex;
  flex-wrap: wrap;
}

.information-header {
  font-size: 14px;
  padding-bottom: 9px;
  line-height: 11px;
  color: #666666;
}
</style>
