<template>
  <div class="card card-body h-100 mt-4 mb-4">
    <div class="mb-3">
      <div class="flex justify-content-between">
        <div class="p-3" style="flex: 1;">
          <h4 class="card-title mb-4">Нежелательные события за период</h4>
          <div
            v-for="(list, i) in widgetData"
            :key="i"
            class="general-info-widget__list"
            :class="{ 'mb-4': i + 1 !== widgetData.length }"
          >
            <h5 class="text-secondary text-base">{{ list.title }}</h5>
            <div v-for="item in list.items" :key="item.title" class="general-info-widget__list-item">
              <div class="general-info-widget__list-title">
                <NextIcon class="text-primary mr-2" icon="cross" size="10px" />
                {{ item.title }}
              </div>
              <div class="general-info-widget__list-value">
                <SpinLoader v-if="isLoading" class="w-16" />
                <template v-else>
                  {{ item.value }}
                  <div
                    v-if="item.percent"
                    class="general-info-widget__list-percent"
                    :class="{ [`text-${item.color}`]: item.color }"
                  >
                    ({{ item.percent }})
                  </div>
                  <div v-else class="general-info-widget__list-percent" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3" style="flex: 2;">
          <!-- <h4 class="card-title mb-4">Нежелательные события</h4> -->
          <apexchart
            width="100%"
            height="300px"
            :options="chartOptions"
            :series="chartSeries"
          />
        </div>
      </div>

      <div class="pt-4">
        <apexchart
          width="100%"
          height="250px"
          :options="chartOptions2"
          :series="chartSeries2"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import api from '@/api'
import _ from 'lodash'
import moment from 'moment';
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import NextIcon from '@/components/widgets/simple-components/icon'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]

const widgetData =  ref({
  general: {
    title: null,
    items: {
      processes: {
        title: 'Сегодня',
        value: 1
      },
      requirements: {
        title: 'Всего с начала года',
        value: 2
      },
      checkedRequirements: {
        title: 'в т.ч. извещений в контролирующий орган',
        value: 3
      }
    }
  },
  required: {
    title: null,
    items: {
      passed: {
        title: 'Администрация',
        value: 3,
        percent: 2,
        color: 'danger'
      },
      // unpassed: {
      //   title: 'Всего несоответствий',
      //   value: 0,
      //   percent: 0,
      //   color: 'danger'
      // }
    }
  },
})

const chartOptions = {
  title: {
    text: 'Нежелательные события',
    align: 'center',
    style: {
      fontSize: 20,
      fontWeight: 500
    }
  },
  chart: {
    id: 'vuechart-example',
    type: 'bar',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  tooltip: {
    style: {
      fontSize: 18
    }
  },
  colors: ['#31AF92', 'tomato'],
  plotOptions: {
    bar: {
      columnWidth: '40%'
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: months
  },
}

const chartSeries = [
  {
    name: 'Пациент не пострадал',
    data: [30, 40, 45, 50, 49, 60, 70, 91]
  },
  {
    name: 'Пациент пострадал',
    data: [30, 40, 45, 50, 49, 60, 70, 91]
  },
]

const chartOptions2 = {
  title: {
    text: 'Динамика изменения общего уровня Рисков по МО в %',
    align: 'center',
    style: {
      fontSize: 20,
      fontWeight: 500
    }
  },
  chart: {
    id: 'vuechart-example2',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    }
  },
  tooltip: {
    style: {
      fontSize: 18
    }
  },
  colors: ['#31AF92', '#0284c7'],
  plotOptions: {
    bar: {
      columnWidth: '40%'
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: months
  },
}
const chartSeries2 = [
  {
    name: 'series-1',
    data: [30, 40, 45, 50, 49, 60, 70, 91]
  },
]

onMounted(() => {
  fetchData()
})

const fetchData = async () => {
  try {
    //
  } catch (error) {
    console.log('ERROR', error);
  }
}
</script>


<style lang="scss" scoped>
.general-info-widget {
  &__list {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-size: 1.125rem;

      &::before {
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        width: 100%;
        border: 1px dashed #ddd;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: 300;
      background: #fff;
    }

    &-value {
      position: relative;
      display: flex;
      padding-left: 8px;
      font-weight: 500;
      background: #fff;
      z-index: 1;
    }

    &-percent {
      width: 30px;
      margin-left: 0.5rem;
      color: #aaa;
      text-align: right;
      white-space: nowrap;
    }
  }
}
</style>
