<script setup>
import { ref, computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useMessage, } from 'naive-ui'
import moment from 'moment'
import { useRoute, } from 'vue-router'

import api from '@/api'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import DropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import FileLink from '@/components/common/FileLink'
import ModalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import InputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'

const message = useMessage()
const store = useStore()
const route = useRoute()

const uploadModalIsOpen = ref(false)
const deleteModalIsOpen = ref(false)
const documentToDelete = ref({})

const sort = reactive({
  type: 'date',
  direction: 'desc'
})

const riskDocs = ref([])
const uploadFile = ref(null)
const file = ref('')
const filesTab = ref('main')
const name = ref('')
const category = ref('')
const openedEditFile = ref(null)
const editedFile = reactive({
  name: '',
  category_id: ''
})

const documentCategoryId = ref(null)
const riskId = computed(() => route.params.id)

const filteredFiles = computed(() => {
  let files = riskDocs.value

  if (documentCategoryId.value?.id) {
    files = riskDocs.value.filter((fileObject) => fileObject.category_id === documentCategoryId.value.id)
  }

  return files
})

const categoryListWithAllOption = computed(() => {
  return [{ id: undefined, text: 'Все' }, ...store.getters.documentsCategory]
})
const categoryList = computed(() => {
  return store.getters.documentsCategory
})
const isValidUpload = computed(() => file.value !== '' && name.value !== '' && category.value !== '')

const getCategoryTextById = (id) => {
  return categoryList.value.find((category) => category.id === id)?.text
}

onMounted(() => {
  fetchDocs()
  store.dispatch('DOCUMENTS_REFRESH_ALL_CATEGORIES')
})

async function fetchDocs() {
  const { data: { data }, status } = await api.get(`risk-registry/get-files?risk_id=${riskId.value}`)
  
  if (status === 200) {
    riskDocs.value = data
  }
}

function changeDocumentCategory(value) {
  documentCategoryId.value = value
}

function toggleDocumentsUpload() {
  uploadModalIsOpen.value = !uploadModalIsOpen.value
}

function clearUploadModal() {
  uploadModalIsOpen.value = false
  clearFile()
  setName('')
  selectCategory({ id: '' })
}

function showDeleteModal(item) {
  documentToDelete.value = item
  deleteModalIsOpen.value = true
}

function changeSort(value) {
  console.log(value)
}

function appendFile(e) {
  let files = e.target.files
  file.value = files[0]
}

function openUpload() {
  uploadFile.value.click()
}

function setName(value) {
  name.value = value
}

function selectCategory(value) {
  category.value = value.id
}

async function uploadHandler() {
  let formData = new FormData()

  formData.append('name', name.value)
  formData.append('category_id', category.value)
  formData.append('file', file.value)

  const url = `risk-registry/upload-file?risk_id=${riskId.value}/`
  const { data, status }  = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  // let result = await store.dispatch('INSPECTION_DOCUMENTS_UPLOAD_FILE', {
  //   formData,
  //   meeting_id: riskId.value
  // })

  if (status === 200 && data.id) {
    clearUploadModal()
    message.success('Файл загружен')
    uploadModalIsOpen.value = false
    fetchDocs()
  }
}

async function onDeleteDocument() {
  const { model_id, id } = documentToDelete.value
  const url = `/risk-registry/delete-file?risk_id=${model_id}&file_id=${id}`
  const resp = await api.delete(url)

  if (resp.status === 200) {
    message.success('Успешно')
    documentToDelete.value = {}
    deleteModalIsOpen.value = false
    fetchDocs()
  }
}

function selectCategoryForEditFile(value) {
  editedFile.category_id = value.id
}

function selectEditedFileName(value) {
  editedFile.name = value
}

function clearFile() {
  file.value = ''
  uploadFile.value.value = ''
}

async function onEditSave() {
  const data = {
    file_id: openedEditFile.value.id,
    meeting_id: riskId.value,
    name: editedFile.name || openedEditFile.value.name,
    category_id: editedFile.category_id || openedEditFile.value.category_id
  }

  // const resp = await store.dispatch('INSPECTION_EDIT_FILE', data)

  // if (resp.status === 200) {
  //   openedEditFile.value = null
  //   message.success('Успешно')
  //   store.dispatch('INSPECTION_GET_DOCUMENTS', {
  //     meeting_id: riskId.value
  //   })
  // }
}
</script>

<template>
  <div class="content-block">
      <div class="content-header">Документы</div>
      <div class="documents-controls">
        <DropdownListWithHeader
          class="document-types"
          header="Категории документов"
          :search="false"
          :list="categoryListWithAllOption"
          @select-handler="changeDocumentCategory"
        />
        <NextButton type="plus" color="green" text="Добавить документ" @click-handler="toggleDocumentsUpload" />
      </div>

      <div class="content-info">
        <div class="list">
          <div v-if="filteredFiles.length > 0" class="list-head">
            <div class="name-column sort-item" @click="changeSort('name')">
              Название документа
              <span class="sort-icon" :class="[{ active: sort.type === 'name' }, sort.direction]" />
            </div>
            <div class="file-column sort-item" @click="changeSort('file')">
              Файл
              <span class="sort-icon" :class="[{ active: sort.type === 'file' }, sort.direction]" />
            </div>
            <div class="category-column sort-item" @click="changeSort('category')">
              Категория
              <span class="sort-icon" :class="[{ active: sort.type === 'category' }, sort.direction]" />
            </div>
            <div class="date-column sort-item" @click="changeSort('date')">
              Дата загрузки
              <span class="sort-icon" :class="[{ active: sort.type === 'date' }, sort.direction]" />
            </div>
            <div class="events-column">Действия</div>
          </div>
          <div v-if="filteredFiles.length > 0" class="list-content">
            <template v-for="(item, key) in filteredFiles" :key="key">
              <div class="document-item" :class="{ gray: key % 2 !== 0 }">
                <div class="name-column">{{ item.name || item.title }}</div>
                <FileLink
                  class="file-column"
                  :link="`/risk-registry/download-file?risk_id=${riskId}&file_id=${item.id}`"
                  :name="`${item.old_name.substr(0, 30)}.${item.extension}`"
                />
                <div class="category-column">{{ getCategoryTextById(item.category_id) }}</div>
                <div class="date-column">{{ moment(item.uploaded_at * 1000).format('DD.MM.YYYY') }}</div>
                <div v-if="object !== 'public'" class="events-column">
                  <div v-if="!item.protected" class="green-edit-icon icon pointer" @click="openedEditFile = item" />
                  <div class="ml-3 red-christ-icon icon pointer" @click="showDeleteModal(item)" />
                </div>
                <div v-else class="events-column">
                  <template v-if="item.object">{{ item.object }}</template>
                </div>
              </div>
            </template>
          </div>
          <div v-if="filteredFiles.length === 0" class="no-content">Здесь пока нет документов.</div>
        </div>
        <!-- <Paginate
          v-if="count / perPage > 1"
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="changePage"
        /> -->
      </div>

      <ModalWindow v-if="deleteModalIsOpen" header="Удалить документ" @close-handler="deleteModalIsOpen = false">
        <div class="document">
          <div class="description">
            Вы уверены, что хотите безвовзвратно удалить этот документ? Восстановить его после удаления будет
            невозможно.
          </div>
          <div class="buttons">
            <NextButton text="УДАЛИТЬ ДОКУМЕНТ" type="next" color="green" @click-handler="onDeleteDocument" />
            <NextButton text="ОТМЕНИТЬ" type="close" color="white" @click-handler="deleteModalIsOpen = false" />
          </div>
        </div>
      </ModalWindow>

      <ModalWindow
        v-if="Boolean(openedEditFile)"
        header="Редактировать документ"
        :content-no-overflow="true"
        @close-handler="openedEditFile = null"
      >
        <div style="width: 600px; padding: 20px">
          <div class="document-header">Данные о файле:</div>
          <div class="file-info">
            <div class="file-item">
              <InputWithHeader
                type="text"
                header="Название документа"
                placeholder="Введите название документа"
                :start-text="openedEditFile.name"
                :required="true"
                @input-handler="selectEditedFileName"
              />
            </div>
            <div class="file-item">
              <DropdownListWithHeader
                :start-item="{ id: openedEditFile.category_id, text: openedEditFile.category?.text }"
                placeholder="Выберите категорию"
                header="Категория документа"
                :required="true"
                :search="false"
                :autoclose="true"
                :list="categoryList"
                @select-handler="selectCategoryForEditFile"
              />
            </div>
          </div>
          <div class="document-text" style="padding-bottom: 15px">
            <span>*</span> Поля отмеченные звёздочкой являются обязательными
          </div>
          <div class="buttons">
            <NextButton type="next" color="green" text="СОХРАНИТЬ" @click="onEditSave" />
          </div>
        </div>
      </ModalWindow>

      <ModalWindow
        v-if="uploadModalIsOpen"
        header="Загрузка документа"
        :content-no-overflow="true"
        @close-handler="clearUploadModal"
      >
        <div
          v-if="filesTab === 'main'"
          class="content"
          :class="{ big: (type === 'process' || type === 'requirement') && id == '' }"
        >
          <input ref="uploadFile" type="file" hidden @change="appendFile" />
          <div class="select">
            <div
              v-if="file === ''"
              ref="uploadArea"
              class="upload-area"
              :class="{ big: file === '' && publicUpload }"
              @click="openUpload"
            >
              <div class="upload-icon" />
              <div class="upload-header">Загрузить документ</div>
              <div class="upload-description">Перетащите ваш файл в эту область или выберите файл</div>
            </div>
            <!-- <div v-if="file === '' && !publicUpload" class="upload-area" @click="filesTab = 'file-manager'"> -->
            <div v-if="file === '' && !publicUpload" class="upload-area">
              <div
                class="file-icon big-icon"
                style="width: 40px; height: 40px; background-size: contain; background-repeat: no-repeat"
              />
              <div class="upload-header">Выбрать документ (в разработке)</div>
              <div class="upload-description">Выберите файл из загруженных ранее</div>
            </div>
          </div>
          <div class="document-info">
            <div v-if="file !== ''" class="info">
              <div class="file-text">
                <div class="file-icon" />
                {{ file.name }}
              </div>
              <div v-if="!file.id" class="file-size">({{ (file.size / 1024).toFixed(2) }} КБ)</div>
              <div v-else class="file-info-date">
                Загружен:
                <span style="color: #00cccc">{{ $helper.makeDateTime(file.upload_date) }}</span>
              </div>
              <div class="file-delete" @click="clearFile">
                Удалить
                <div class="file-delete-icon icon" />
              </div>
            </div>
            <div class="document-header">Данные о файле:</div>
            <div class="file-info">
              <div class="file-item">
                <InputWithHeader
                  v-if="!file.id"
                  header="Название документа"
                  :required="true"
                  type="text"
                  placeholder="Введите название документа"
                  @input-handler="setName"
                />
                <InputWithHeader
                  v-else
                  :disabled="true"
                  :start-text="file.name"
                  header="Название документа"
                  :required="true"
                  type="text"
                  placeholder="Введите название документа"
                />
              </div>
              <div class="file-item">
                <DropdownListWithHeader
                  v-if="!file.id"
                  :search="false"
                  :required="true"
                  placeholder="Выберите категорию"
                  header="Категория документа"
                  :autoclose="true"
                  :list="categoryList"
                  @select-handler="selectCategory"
                />
                <InputWithHeader
                  v-else
                  :disabled="true"
                  :start-text="file.text"
                  header="Категория документа"
                  :required="true"
                  type="text"
                  placeholder="Выберите категорию"
                />
              </div>
            </div>

            <div class="document-text" style="padding-bottom: 15px">
              <span>*</span> Поля отмеченные звёздочкой являются обязательными
            </div>
          </div>
          <div class="content-buttons">
            <NextButton
              type="next"
              color="green"
              text="ДОБАВИТЬ"
              :class="{ inactive: !isValidUpload }"
              :disabled="!isValidUpload"
              @click="uploadHandler"
            />
            <NextButton type="close" color="white" text="ОТМЕНИТЬ" @click-handler="clearUploadModal" />
          </div>
        </div>
      </ModalWindow>
    </div>
</template>

<style scoped>
.document-types {
  width: 320px;
}

.documents-controls {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 20px;
}

.file-info-date {
  width: 250px;
  flex: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.back-button {
  padding: 20px 40px;
  border-radius: 6px;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  cursor: pointer;
  display: flex;
  width: fit-content;
  margin-bottom: 20px;
}
.arrow-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='11' viewBox='0 0 6 11' fill='none'%3E%3Cg filter='url(%23filter0_d_332_93)'%3E%3Cpath d='M0.209113 1.42248C0.0738297 1.25599 -1.81198e-05 1.04801 -1.81198e-05 0.833484C-1.81198e-05 0.61896 0.0738297 0.410975 0.209113 0.244484C0.269514 0.168465 0.346298 0.107064 0.433738 0.0648581C0.521179 0.0226525 0.61702 0.000732422 0.714113 0.000732422C0.811207 0.000732422 0.907047 0.0226525 0.994488 0.0648581C1.08193 0.107064 1.15871 0.168465 1.21911 0.244484L4.79111 4.41048C4.9264 4.57697 5.00024 4.78496 5.00024 4.99948C5.00024 5.21401 4.9264 5.42199 4.79111 5.58848L1.21911 9.75548C1.15871 9.8315 1.08193 9.8929 0.994488 9.93511C0.907047 9.97732 0.811207 9.99924 0.714113 9.99924C0.61702 9.99924 0.521179 9.97732 0.433738 9.93511C0.346298 9.8929 0.269514 9.8315 0.209113 9.75548C0.0738297 9.58899 -1.81198e-05 9.38101 -1.81198e-05 9.16648C-1.81198e-05 8.95196 0.0738297 8.74397 0.209113 8.57748L3.27611 4.99948L0.209113 1.42248Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_332_93' x='0' y='0.000732422' width='6.00024' height='10.9985' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='1' dy='1'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.0117647 0 0 0 0 0.533333 0 0 0 0 0.52549 0 0 0 0.5 0'/%3E%3CfeBlend mode='multiply' in2='BackgroundImageFix' result='effect1_dropShadow_332_93'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_332_93' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  width: 10px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  margin-right: 10px;
}
.accordion-item {
  padding-bottom: 10px;
}
.accordion-list {
  height: 250px;
  overflow-y: auto;
  padding: 10px;
  border: 1px dashed #cccc;
}
.big .file-info {
  flex-direction: row;
}
.info {
  display: flex;
  min-width: 650px;
}
.file-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.med-item__text {
  font-size: 12px;
}
.med-item {
  display: flex;
  padding: 10px;
  align-items: center;
}
.document-append {
  padding-bottom: 15px;
}
.content-list {
  max-height: 145px;
  overflow-y: auto;
  flex-wrap: wrap;
  display: flex;
  max-width: 500px;
  width: 500px;
}
.content-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.file-icon {
  margin-right: 10px;
  flex: none;
  width: 10px;
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_536_6715)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 4.95831L9.9585 4.99982L10 4.99982L10 9.49982L10 10.4998L-4.37597e-07 10.4998L-1.34756e-06 9.49982L-4.41942e-08 1.49982L-4.82844e-10 0.499815L5.5 0.499816L5.5 0.519228L5.53046 0.488769L10 4.95831ZM1 9.49982L9 9.49982L9 5.99982L4.5 5.99982L4.5 4.99982L5.5 4.99982L8.62729 4.99982L5.5 1.87253L5.5 4.99982L4.5 4.99982L4.5 1.49981L1 1.49981L1 9.49982Z' fill='%231CC8AB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_536_6715'%3E%3Crect width='10' height='10' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.file-delete-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.281997 8.87647L3.65724 5.50123L0.281998 2.12599C0.113436 1.94548 0.0226469 1.70643 0.0268716 1.45949C0.0310964 1.21255 0.130552 0.977391 0.305191 0.802752C0.47983 0.628114 0.714988 0.527437 0.961928 0.523212C1.20887 0.518987 1.44792 0.610997 1.62843 0.779559L5.00367 4.14747L8.37891 0.772235C8.55863 0.596346 8.80004 0.498429 9.05151 0.500019C9.30298 0.501609 9.54419 0.602633 9.72168 0.78078C9.89917 0.958928 9.99934 1.20069 10 1.45217C10.0007 1.70364 9.90189 1.94448 9.72534 2.12355L6.3501 5.49879L9.72534 8.87403C9.8939 9.05454 9.98591 9.29359 9.98169 9.54053C9.97746 9.78747 9.87678 10.0226 9.70215 10.1973C9.52751 10.3719 9.29235 10.4714 9.04541 10.4756C8.79847 10.4798 8.55942 10.389 8.37891 10.2205L5.00367 6.84522L1.62843 10.2205C1.54025 10.3092 1.43512 10.3787 1.31959 10.4268C1.20407 10.4748 1.08095 10.5 0.955824 10.5C0.830695 10.5 0.706358 10.4748 0.590835 10.4268C0.475311 10.3787 0.370179 10.3092 0.281997 10.2205C0.193022 10.1328 0.122557 10.0281 0.0744787 9.91284C0.0263992 9.79758 0.00213201 9.67396 0.00245722 9.54907C0.00265655 9.42408 0.0276552 9.29948 0.0756994 9.18408C0.123744 9.06869 0.193431 8.96467 0.281997 8.87647Z' fill='%23D65959'/%3E%3C/svg%3E%0A");
  width: 10px;
  height: 10px;
}
.file-size {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  padding-left: 5px;
  text-align: left;
  color: #666666;
  width: 120px;
  flex: none;
}
.big .file-item {
  width: 45%;
  padding-bottom: 0;
}
.file-item {
  width: 100%;
  padding-bottom: 10px;
}
.file-delete {
  cursor: pointer;
  width: 80px;
  color: #d65959;
  flex: none;
}
.file-text {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
}
.file-info {
  border: 1px dashed #cccccc;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.document-text span {
  color: #1cc8ab;
}
.document-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #666666;
}
.document-header {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  padding-bottom: 13px;
  color: #333333;
}

.select {
  display: flex;
  justify-content: space-between;
}
.upload-area.big {
  width: 100%;
}
.upload-area {
  color: #1cc8ab;
  cursor: pointer;
  margin-bottom: 12px;
  min-height: 50px;
  width: 49%;
  border: 1px dashed #37c389;
  padding: 20px 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.upload-header {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding-bottom: 7px;
  color: #1cc8ab;
}
.upload-description {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1cc8ab;
}
.file-icon.big-icon {
  width: 40px;
  height: 32px;
  margin-bottom: 10px;
}
.upload-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='32' viewBox='0 0 40 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.4143 13L19 17.5857V0H21V17.7277L25.7279 12.9998L27.1421 14.4141L21.4852 20.0709L21.4854 20.0711L20.0712 21.4853L20.071 21.4851L18.6568 20.0709L13.0001 14.4142L14.4143 13ZM2 30L2 12H0V30V32H2H38H40V30V12H38V30H2Z' fill='%231CC8AB'/%3E%3C/svg%3E%0A");
  width: 40px;
  height: 32px;
  margin-bottom: 10px;
}
.content.big {
  width: calc(100vw - 200px);
}
.content {
  width: 100%;
  padding: 20px;
}

.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.list-head {
  font-size: 16px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}
.events-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 160px;
  padding-left: 20px;
  flex: none;
}
.category-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 175px;
  padding-left: 20px;
  flex: none;
}

.file-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  flex: none;
  padding-left: 20px;
  word-wrap: break-word;
}
.name-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 20px;
  border-left: 1px solid #cccccc;
}
.date-column {
  width: 200px;
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  flex: none;
}

.red-christ-icon {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}

.green-edit-icon {
  width: 12px;
  height: 12px;
}

.document-item {
  display: flex;
  border: 1px solid #cccccc;
}

.description {
  padding-bottom: 30px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.document {
  padding: 20px;
}
</style>