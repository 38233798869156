<template>
  <div v-if="isLoading" class="text-center pt-5">
    <p>Загрузка...</p>
    <NSpin size="large" />
  </div>

  <div v-if="!isLoading && chartsList?.length === 0" class="text-center pt-3">
    <p>Нет данных</p>
  </div>

  <div v-if="!isLoading && chartsList?.length">
    <div v-for="chart in chartsList" :key="chart.id" ref="chart" class="mb-5">
      <div v-if="enableDataEnter" class="flex justify-content-end position-relative">
        <NButton class="position-absolute" style="z-index: 10" size="small" @click="() => buttonClick(chart)">
          Внести данные
        </NButton>
      </div>

      <h6 v-if="!enableDataEnter" class="text-center">
        <RouterLink :to="`/process/${chart.process_id}?indicators=${chart.id}`">
          <span>{{ `${chart.name}${getSuffix(chart.input_type)} ` }}</span>
          <span class="text-decoration-underline">{{ `${chart.term_name} ` }}</span>
          <span>{{ `(${chart.medtype.text})` }}</span>
        </RouterLink>
      </h6>

      <apexchart
        width="100%"
        height="280px"
        :options="chart.options"
        :series="chart.series"
        @click="(event, chartContext, config) => clickHandler(event, chartContext, config, chart)"
      />
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router'
import { NButton, NSpin } from 'naive-ui'

export default {
  components: { NButton, NSpin, RouterLink },
  props: {
    isLoading: {
      type: Boolean
    },
    chartsList: {
      type: Array
    },
    enableDataEnter: {
      type: Boolean
    },
    buttonClick: {
      type: Function
    }
  },
  watch: {
    chartsList: {
      handler(val) {
        if (this.$refs.chart && this.$route.query.indicators) {
          const elementIndex = val.findIndex(el => el.id == this.$route.query.indicators)
          const element = this.$refs.chart[elementIndex]

          if (element) {
            element.scrollIntoView({ block: "center", behavior: "smooth" })
            element.classList.add("attention")
          }
        }
        
      },
      deep: true
    }
  },
  methods: {
    getSuffix(input_type) {
      return input_type === 2 ? ', %' : ''
    }
  }
}
</script>

<style scoped>
.attention {
  animation-name: zoomOut;
  animation-duration: 3s;
}

@keyframes zoomOut {
  from {
    transform: scale(0.6) translateX(0%);
  }
  to {
    transform: scale(1)  translateX(0%);
  }
}
</style>