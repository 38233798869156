<script setup>
import { onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { NButton, NInput } from 'naive-ui'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import { useRisksStore } from '@/store/risks'

const risksStore = useRisksStore()
const risksStoreRefs = storeToRefs(risksStore)

const tableData = computed(() => {
  const { value } = risksStoreRefs.list 

  if (sort.value === 'ASC') {
    return [...value].sort((a,b) => Number(a.id) - Number(b.id))
  }

  return value
})

const search = ref('')
const sort = ref('')

const columns = [
  { key: 'id', title: '№', width: '80px', sortable: true, sortOrder: 'DESC', fontSize: '0.7rem', padding: '10px' },
  {
    key: 'type',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Вид риска'
  },
  {
    key: 'process',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Раздел ВКК',
    render: (row, item) => item.text
  },
  {
    key: 'actionCategory',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Наименование риска',
    render: (row, item) => item?.name
  },
  {
    key: 'owner',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Владелец риска'
  },
  {
    key: 'probability',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Оценка вероятности'
  },
  {
    key: 'severity',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Оценка тяжести риска'
  },
  {
    key: 'level',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Уровень риска'
  },
  {
    key: 'activities',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Мероприятия по управлению рисками'
  },
  {
    key: 'activities_plan',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'План мероприятий по управлению риском'
  },
  {
    key: 'responsible',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Ответственный',
    render: (row, item) => item.name
  },
  {
    key: 'button',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'НС',
  },
]

onMounted(() => {
  risksStore.fetch({})
})

const onSearch = (e) => {
  search.value = e
}

const searchSubmit = () => {
  risksStore.fetch({
    search: search.value
  })
}

const onClear = () => {
  risksStore.fetch({})
}

const changeSort = (s) => {
  sort.value = s.order
}
</script>

<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__heading">Реестр рисков</div>
      </div>
    </div>

    <div class="content">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex flex-wrap gap-3">
          <NInput placeholder="Поиск по наименованию" style="width: 400px;" size="large" clearable @input="onSearch" @clear="onClear" />
          <NButton @click="searchSubmit">Поиск</NButton>
        </div>
        <RouterLink :to="`/risks/create`">
          <NButton type="primary"> Регистрация риска </NButton>
        </RouterLink>
      </div>

      <NextTable
        class="table-responsive-xl"
        :columns="columns"
        :table-data="tableData"
        striped
        @change:sort="changeSort"
      >
        <template #id="{ value }">
          <RouterLink :to="`/risks/${value}`">{{ value }}</RouterLink>
        </template>

        <template #type="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #process_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #name="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #owner="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #probability="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #severity="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #level="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #activities="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #activities_plan="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #responsible_id="{ value }">
          <span>{{ value }}</span>
        </template>
        <template #button="{ row }">
          <div class="custom-buttons flex">
            <RouterLink class="flex" :to="`/incidents/create?risk_id=${row.id}`">
              <span class="custom">НС</span>
              <!-- <span class="custom">Регистрация</span> -->
            </RouterLink>
          </div>
        </template>
      </NextTable>
      <!-- <div v-if="count / perPage > 1" class="pagination-container">
        <Paginate
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="fetch"
        />
      </div> -->
      <!-- <NextAlert v-else type="error" title="Произошла ошибка" :text="error" /> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-buttons {
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  transition: ease .2s;
  
  &:hover {
    opacity: 0.85;
  }

  span {
    height: 100%;
    display: block;
    padding: 8px;
  }

  span.custom:nth-child(1) {
    // background: #FA454A;
    background: #37c4a3;
    color: #fff;
  }

  span.custom:nth-child(2) {
    background: #37c4a3;
    color: #fff;
  }
}
</style>