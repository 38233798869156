<template>
  <NH6 class="mb-2">Выберите период</NH6>
  <NSelect v-model:value="currentPeriod" :options="yearsOptions" style="max-width: 300px" size="large" />

  <ParametersCharts
    enable-data-enter
    :is-loading="parametersChartsListIsLoading"
    :charts-list="parametersChartsList"
    :button-click="(chart) => openModal(chart)"
  />

  <NModal v-model:show="showModal" :on-after-leave="onModalClose">
    <NCard style="width: 800px" class="">
      <div class="flex justify-content-between align-items-center mb-3">
        <p class="mb-0 pr-4">{{ chartInModal.name }}{{ getSuffix(chartInModal.input_type) }}</p>
      </div>

      <div class="flex justify-content-end mb-2">
        <!-- <NSelect v-model:value="periodType" :options="periodList" size="large" style="width: 300px" /> -->

        <NPopconfirm :positive-text="null" :negative-text="null">
          <template #trigger>
            <NSelect v-model:value="periodType" :options="periodList" size="large" style="width: 300px" />
          </template>
          <div style="font-size: 15px; margin-bottom: -5px; font-weight: 700">При изменении (сохранении) периода введенные ранее данные пропадут</div>
        </NPopconfirm>
      </div>

      <div class="flex mb-4 justify-content-center">
        <div v-for="(period, periodIndex) in currentModalPeriod" :key="period" class="cell ml-1">
          <p style="font-size: 13px" class="text-center mb-1">{{ period }}</p>
          <NInput
            v-if="periodType === 'months'"
            v-model:value="cellsDataByMonth[periodIndex]"
            placeholder=""
            class="rounded-0 text-center"
            size="medium"
            :allow-input="onlyAllowNumber"
            @input="(e) => cellChange(e, periodIndex)"
          />

          <NInput
            v-if="periodType === 'quarter'"
            v-model:value="cellsDataByQuarter[periodIndex]"
            placeholder=""
            class="rounded-0 text-center"
            size="medium"
            :allow-input="onlyAllowNumber"
            @input="(e) => cellChange(e, periodIndex)"
          />
        </div>
      </div>

      <div v-if="periodType === 'custom_data'" class="flex mb-4 justify-content-center">
        <NCalendar #="{ year, month, date }" class="w-100">
          <div class="h-100 w-100 flex align-items-center" :class="{ colored: Boolean(calendar[moment(`${year}/${month}/${date}`).format('YYYY-MM-DD')]) }" style="width: calc(100% + 20px) !important; margin: -3px -10px 0px; padding: 8px;">
            <NInput
              :value="calendar[moment(`${year}/${month}/${date}`).format('YYYY-MM-DD')]"
              :allow-input="onlyAllowNumberForCalendar"
              style="font-size: 20px;"
              @input="onCalendarInput($event, {year, month, date})"
            />
          </div>
        </NCalendar>
      </div>

      <template #footer>
        <div class="flex justify-content-between align-items-center">
          <div>
            <!-- <p :class="`mb-0 ${checkValidModalValues.length ? 'text-danger' : ''}`">
              Допустимые значения от {{ chartInModal.min }} до {{ chartInModal.max }}
            </p> -->
            <p class="mb-0">Целевой критерий: {{ chartInModal.target_criteria }}</p>
          </div>
          <div class="flex justify-content-end">
            <!-- <span class="alert alert-warning">При изменении периода введенные ранее данные пропадут</span> -->
          </div>
          <NButton size="small" @click="saveModal">Сохранить</NButton>
        </div>
      </template>
    </NCard>
  </NModal>
</template>

<script>
import { NButton, NCard, NModal, NSelect, NH6, NInput, NRadioGroup, NRadioButton, NSpace, NSpin, NCalendar, NPopconfirm } from 'naive-ui'
import ParametersCharts from '@/components/common/ParametersCharts'
import moment from 'moment'
// https://bitbucket.org/afinadev/med-2022/commits/d49168d713ef19d08ba5640f76ab07cdd2c6387e

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
]
// const year = ['']
const quarter = ['1 квартал', '2 квартал', '3 квартал', '4 квартал']
const periodMap = {
  months,
  quarter,
  // year
}

export default {
  components: {
    NButton,
    NModal,
    NCard,
    NSelect,
    NH6,
    NInput,
    NRadioGroup,
    NRadioButton,
    NSpace,
    NSpin,
    NCalendar,
    NPopconfirm,
    ParametersCharts
  },
  props: {
    process: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment: moment,
      calendar: {},
      showModal: false,
      currentPeriod: new Date().getFullYear(),
      months,
      periodList: [
        { value: 'quarter', label: 'Квартал' },
        { value: 'months', label: 'Месяц' },
        { value: 'custom_data', label: 'Дата' }
      ],
      onlyAllowNumber: (value) => {
        if (!value) return true
        // Заменяем запятую на точку
        const normalizedValue = value.replace(/,/g, '.')
        // Проверяем, соответствует ли преобразованное значение формату числа
        return /^\d*\.?\d*$/.test(normalizedValue)
      },
      onlyAllowNumberForCalendar: (value) => {
        if (!value) return true
        // Заменяем запятую на точку
        const normalizedValue = value.replace(/,/g, '.')
        // Проверяем, соответствует ли преобразованное значение формату числа
        return /^\d+(\.\d{0,3})?$/.test(normalizedValue)
      },
      periodType: 'months',
      yearsOptions: [
        { value: 2022, label: 2022 },
        { value: 2023, label: 2023 },
        { value: 2024, label: 2024 },
        { value: 2025, label: 2025 },
        { value: 2026, label: 2026 }
      ],
      chartInModal: {},
      cellsDataByMonth: [],
      cellsDataByQuarter: [null, null, null, null],
      parametersChartsListIsLoading: false,
      parametersChartsList: [],
      options: {
        tooltip: {
          enabledOnSeries: [0],
          style: {
            fontSize: 18
          }
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        title: {
          align: 'center'
        },
        xaxis: {
          categories: months
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              const stringified = String(val)
              
              if (stringified.includes('.') && stringified.split('.')[1].length > 1) {
                return val.toFixed(0)
              }
              if (val) {
                return val.toFixed(0)
              }
              return val
            }
          },
        },
        colors: ['#31AF92', '#0284c7'],
        plotOptions: {
          bar: {
            columnWidth: '40%'
          }
        },
        stroke: {
          width: [0, 3]
        }
      },
      series: [
        {
          name: 'Название показателя',
          type: 'bar',
          data: []
        }
      ]
    }
  },
  computed: {
    currentModalPeriod: (v) => {
      return periodMap[v.periodType]
    },
    // checkValidModalValues: (v) => {
    //   // для непроцентных значений проверку не делать
    //   if (v.chartInModal.input_type === 1) {
    //     return []
    //   }

    //   return v.cellsDataByMonth
    //     .map((cellValue) => {
    //       if (cellValue < v.chartInModal.min || cellValue > v.chartInModal.max) {
    //         return cellValue
    //       } else {
    //         return null
    //       }
    //     })
    //     .filter((cellValue) => cellValue !== null) // ?
    // }
  },
  watch: {
    currentPeriod: function () {
      this.fetchActiveIndicatorsList()
      this.cellsDataByMonth = []
    }
  },
  mounted() {
    this.fetchActiveIndicatorsList()
  },
  methods: {
    async fetchActiveIndicatorsList() {
      this.parametersChartsListIsLoading = true

      let {
        data: { data: parameters, ok }
      } = await this.$api.post('/process/get-parameters', {
        process_id: this.process.id,
        active: 1
      })

      parameters = parameters?.map((parameter) => ({
        ...parameter,
        min: Number(parameter.min),
        max: Number(parameter.max),
      }))

      if (ok === 1) {
        const indicators = await this.fetchIndicators()
        const filledParameters = (parameters || [])?.map((parameter) => {
          const matchedIndicator = indicators.find((indicator) => indicator.process_indicator_id === parameter.id)

          const autoFillArray = (array = []) => {
            const arr = [...array]

            while (arr.length < 12) {
              arr.push(null)
            }
            return arr
          }

          let values =
            matchedIndicator?.values.length === 12
              ? matchedIndicator?.values
              : autoFillArray(matchedIndicator?.values)

          if (matchedIndicator?.view_mode === 2) {
            values = matchedIndicator?.values
          }

          if (matchedIndicator?.view_mode === 3) {
            let arr = []
            
            Object.keys(matchedIndicator?.calendar_values)
              .sort()
              .map((key) => {
                arr.push(matchedIndicator?.calendar_values[key])
              })

            values = arr
          }
          
          const chart = {
            ...parameter,
            values,
            view_mode: matchedIndicator?.view_mode,
            calendar_values: matchedIndicator?.calendar_values || {},
            options: this.getCustomOptions({...parameter, ...matchedIndicator}),
            series: this.getCustomSeries({ ...parameter, values }, matchedIndicator)
          }

          return chart
        })
        
        this.parametersChartsList = filledParameters
        this.parametersChartsListIsLoading = false
      }
    },
    async fetchIndicators() {
      const {
        data: { data, ok }
      } = await this.$api.post('/process/get-indicators', {
        process_id: this.process.id,
        year: this.currentPeriod
      })

      if (ok === 1) {
        const indicatorsWithCalendarValues = await this.fetchDailyIndicators(data)
        return indicatorsWithCalendarValues || []
      }
    },
    async fetchDailyIndicators(indicators) {
      
      const promises = indicators.map(async (indicator) => {
        const { data: { data } } = await this.$api.post('/process/get-daily-indicators', {
          process_id: this.process.id,
          year: this.currentPeriod,
          indicator_id: indicator.process_indicator_id
        })

        // в случае, когда приходит массив - работать с ним как с объектом
        // массив приходит только когда data пустая, поэтому нет проверки на .length
        const calendarValues = 
          typeof data === 'object' && !Array.isArray(data)
            ? data
            : {}

        return {
          ...indicator,
          calendar_values: calendarValues 
        }
      })

      const values = await Promise.all(promises)
      return values      
    },
    getSuffix(input_type) {
      return input_type === 2 ? ', %' : ''
    },
    getCustomOptions(chart) {
      const suffix = this.getSuffix(chart.input_type)

      let options = {
        ...this.options,
        title: {
          ...this.options.title,
          text: chart.name + suffix
        },
        yaxis: {
          ...this.options.yaxis,
          min: chart.min
        }
      }
      
      // tickAmount по 10 ставится только если хоть одно значение >= 10, иначе значения в оси дублируются
      if (chart.values?.some((value) => value >= 10)) {
        options.yaxis.tickAmount = 10
      }

      // max задаётся только для процентных значений
      if (chart.input_type === 2) {
        options.yaxis.max = chart.max

        if (chart.max % 10 > 0) {
          options.yaxis.max = (Math.ceil(chart.max / 10) * 10)
          options.yaxis.tickAmount = (Math.ceil(chart.max / 10) * 10) / 10
        }

        options.yaxis.forceNiceScale = false
      }

      if (chart.view_mode === 2) {
        options.xaxis = {
          ...options.xaxis, 
          categories: quarter
        }
      }

      if (chart.view_mode === 3 && Object.keys(chart.calendar_values).length) {
        options.xaxis = {
          ...options.xaxis, 
          categories: Object.keys(chart?.calendar_values).sort().map((date) => moment(date).format('DD.MM.YYYY'))
        }
      }

      return options
    },
    getCustomSeries(chart, matchedIndicator) {
      let trendLineParams
      let trendLineData

      if (matchedIndicator?.view_mode === 3) {
        const values = 
          Object
            .keys(matchedIndicator.calendar_values)
            .sort()
            .map(key => matchedIndicator.calendar_values[key])
            .map((v) => (v === '' || v === null ? null : Number(v)))

        trendLineParams = this.calculateTrendLine(values)
        trendLineData = this.generateTrendLinePoints(values, trendLineParams, chart.min, chart.max)
      } else {
        trendLineParams = this.calculateTrendLine(chart.values)
        trendLineData = this.generateTrendLinePoints(chart.values, trendLineParams, chart.min, chart.max)
      }

      const series = [
        {
          type: 'bar',
          name: chart.name,
          data: chart.values
        }
      ]
      
      if (chart.values.length > 1 && !chart.values.every((v) => v === null)) {
        series.push({
          type: 'line',
          name: 'Тренд',
          data: trendLineData
        })
      }

      return series
    },
    cellChange(value, periodIndex) {
      const normalizedValue = value.replace(',', '.')

      if (this.periodType === 'quarter') {
        this.cellsDataByQuarter[periodIndex] = value

        this.cellsDataByMonth[periodIndex * 3] = value
        this.cellsDataByMonth[periodIndex * 3 + 1] = value
        this.cellsDataByMonth[periodIndex * 3 + 2] = value
      }

      if (this.periodType === 'months') {
        this.cellsDataByMonth[periodIndex] = normalizedValue
      }
    },
    openModal(chart) {
      this.showModal = !this.showModal
      this.chartInModal = chart

      this.periodType = 'months'

      if (chart.view_mode === 3) {
        this.periodType = 'custom_data'
      }
      if (chart.view_mode === 2) {
        this.periodType = 'quarter'
      }

      this.fillCells(chart)
    },
    fillCells(chart) {
      if (chart.view_mode === 1) {
        this.cellsDataByMonth = chart.values
      }

      if (chart.view_mode === 2) {
        this.cellsDataByQuarter = chart.values
      }

      if (chart.view_mode === 3) {
        this.calendar = chart.calendar_values
      }
    },
    onCalendarInput(value, { year, month, date }) {
      const normalizedValue = value.replace(',', '.')
      const validDate =  moment(`${year}/${month}/${date}`).format('YYYY-MM-DD');

      this.calendar[validDate] = normalizedValue
    },
    async submitDailyIndicators() {
      
      const payload = {
        process_id: this.chartInModal.process_id,
        indicator_id: this.chartInModal.id,
        data: this.calendar
      }

      await this.$api.post('/process/set-parameters', {
        view_mode: 3,
        values: [],
        year: this.currentPeriod,
        process_id: this.chartInModal.process_id,
        indicator_id: this.chartInModal.id
      })

      const resp = await this.$api.post('/process/set-daily-indicators', payload)

      if (resp.status === 200) {
        this.fetchActiveIndicatorsList()
        this.$store.dispatch('ALERT_CREATE', { text: resp.data.message, type: 'success' })
        this.showModal = false
      }
    },
    async saveModal() {

      if (this.periodType === 'custom_data') {
        this.submitDailyIndicators()
      } else {

      const viewModeByPeriod = {
        months: 1,
        quarter: 2,
      }[this.periodType]

      const arrayByPeriod = {
        months: this.cellsDataByMonth,
        // quarter: [this.cellsDataByQuarter[0], this.cellsDataByQuarter[1], this.cellsDataByQuarter[2], this.cellsDataByQuarter[3]],
        quarter: this.cellsDataByQuarter,
      }[this.periodType]

      const {
        data: { ok, message }
      } = await this.$api.post('/process/set-parameters', {
        view_mode: viewModeByPeriod,
        values: arrayByPeriod.map((v) => (v === '' || v === null ? null : Number(v))),
        year: this.currentPeriod,
        process_id: this.chartInModal.process_id,
        indicator_id: this.chartInModal.id
      })

      if (ok === 1) {
        this.fetchActiveIndicatorsList()
        this.$store.dispatch('ALERT_CREATE', { text: message, type: 'success' })
        this.showModal = false
      }
    }

    },
    calculateTrendLine(data) {
      let xSum = 0,
        ySum = 0,
        xySum = 0,
        xxSum = 0
      let validCount = 0 // Счетчик для подсчета количества неигнорируемых элементов

      for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) {
          // Проверяем, не равен ли текущий элемент null
          xSum += validCount // Используем validCount вместо i для расчета сумм
          ySum += data[i]
          xySum += validCount * data[i]
          xxSum += validCount * validCount
          validCount++ // Увеличиваем счетчик для каждого неигнорируемого элемента
        }
      }

      if (validCount === 0) {
        // Если все значения были null, возвращаем m и b как NaN или другое значимое значение
        return { m: NaN, b: NaN }
      }

      const m = (validCount * xySum - xSum * ySum) / (validCount * xxSum - xSum * xSum)
      const b = (ySum - m * xSum) / validCount

      return { m, b }
    },
    generateTrendLinePoints(data, { m, b }, min, max) {
      let trendLine = data.map((_, index) => Number((m * index + b).toFixed(5)))
      
      // Если max undefined, используем текущие минимальные и максимальные значения трендовой линии
      if (!max) {
        max = Math.max(...trendLine);
      }

      const scaledValues = this.scaleValuesWithinRange(trendLine, min, max);
      
      return scaledValues;
    },
    scaleValuesWithinRange(values, min, max) {
      const originalMin = Math.min(...values);
      const originalMax = Math.max(...values);

      // Если все значения уже в пределах min и max, ничего делать не нужно
      if (originalMin >= min && originalMax <= max) {
        return values;
      }

      // Масштабируем значения
      const scaledValues = values.map((value) => {
        // Пропорционально адаптируем значение к новому диапазону
        const scaledValue = ((value - originalMin) / (originalMax - originalMin)) * (max - min) + min;
        return scaledValue;
      });

      return scaledValues;
    },
    onModalClose() {
      this.chartInModal = {}
      this.calendar = {}
      this.cellsDataByMonth = []
      this.cellsDataByQuarter = [null, null, null, null]
    },
  }
}
</script>

<style scoped>
.colored {
  background: #37c4a32b;
}
</style>