<template>
  <div class="checklist-report-template">
    <HeaderPdf />
    <template v-if="pageNumber === 1">
      <div class="pb-4">
        <h4 class="font-weight-bold text-center mb-3">
          ПРОТОКОЛ заседания комиссии ВКК и БМД №{{ reportNumber }} от {{ signDate }}
        </h4>
        <div class="text-lg">
          <div class="d-flex">
            по результатам проведения проверки:&nbsp;
            <div class="font-weight-bold">{{ sourceData.assurance.text }}</div>
          </div>
          <div class="d-flex">
            по направлению:&nbsp;
            <div class="font-weight-bold">{{ sourceData.medtype.text }}</div>
          </div>
          <div>раздел: «{{ sourceData.process.text }}»</div>
          <div>Подразделение/отдел: {{ sourceData.subdivision }}</div>
        </div>
      </div>
      <div class="pb-4 text-lg" style="height: 182px" data-height="182">
        <div class="checklist-report-template__order-box">
          <div class="d-flex mb-2">
            <div class="font-weight-bold mr-4">Отчёт №{{ reportNumber }} от {{ stopDate }}</div>
            <div>Аудитор: {{ sourceData?.assurance_user?.name }}</div>
          </div>
          <div class="d-flex">
            <div class="d-flex mr-8">
              <div class="mr-5">
                <div>Начало проверки:</div>
                <div>Завершение проверки:</div>
                <div>Подписан:</div>
              </div>
              <div class="ml-auto">
                <div>{{ planDateTime }}</div>
                <div>{{ stopDateTime }}</div>
                <div>{{ signDate }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-5">
                <div>Проверено требований:</div>
                <div>Неприменимые:</div>
                <div>Соответствий всего:</div>
                <div>Несоответствий всего:</div>
              </div>
              <div class="ml-auto">
                <div>{{ requirementsCount }}</div>

                <div>{{ notApplicableCount }}</div>

                <div>
                  {{ correspondenceCount }} 
                  ({{
                    toPercent(correspondenceCount, correspondenceCount + discrepanciesCount)
                  }}%)
                </div>

                <div>{{ discrepanciesCount }} ({{ toPercent(discrepanciesCount, correspondenceCount + discrepanciesCount) }}%)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!initialEmpty" style="height: 64px" data-height="64">
        <h5 class="font-weight-bold text-center">
          План мероприятий по устранению/предупреждению несоответствий или нежелательных событий
        </h5>
      </div>
    </template>

    <div class="table-section">
      <TablePdf :initial-empty="initialEmpty" :columns="columns" :data="computedTableData">
        <template v-if="sourceData.responsibleUsers.length" #tfoot>
          <tfoot>
            <tr class="no-bg border-top">
              <td :colspan="columns.length" class="pb-5 pt-3 border-right-0 border-left-0">
                <h5 class="font-weight-bold mb-2">Члены комиссии ВКК и БМД:</h5>
                <div v-for="user in sourceData.responsibleUsers" :key="user.id" class="row">
                  <div class="col-6">{{ user.name }}</div>
                  <div class="col-4">{{ user.post }}</div>
                  <div class="col-2"><div class="border-dark border-bottom h-4" /></div>
                </div>
              </td>
            </tr>
          </tfoot>
        </template>
      </TablePdf>
    </div>
    <FooterPdf class="mt-auto" :page-number="pageNumber" />
  </div>
</template>
<script>
import HeaderPdf from '@/components/pdf/common/HeaderPdf'
import FooterPdf from '@/components/pdf/common/FooterPdf'
import TablePdf from '@/components/pdf/common/TablePdf'
import { makeDate, makeDateTime } from '@/helper'
import moment from 'moment';

export default {
  components: { TablePdf, FooterPdf, HeaderPdf },
  props: {
    sourceData: {
      type: Object,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    initialEmpty: {
      type: Boolean
    }
  },
  data() {
    return {
      columns: [
        {
          key: 'requirements',
          title: 'Требования ВКК и БМД',
          width: '30%'
        },
        {
          key: 'discrepancy',
          title: 'Выявленные проблемы/несоответствия',
          width: '30%'
        },
        {
          key: 'events',
          title: 'Мероприятия',
          width: '25%'
        },
        {
          key: 'responsible',
          title: 'Ответственный',
          width: '15%'
        }
      ]
    }
  },
  computed: {
    reportNumber: (vm) => vm.sourceData?.number?.replace('_', '-'),
    planDateTime: (vm) => makeDateTime(vm.sourceData?.plan_date * 1000 || vm.sourceData?.create_date),
    stopDate: (vm) => makeDate(vm.sourceData?.finish_date),
    stopDateTime: (vm) => makeDateTime(vm.sourceData?.finish_date),
    signDate: (vm) => makeDate(vm.sourceData?.sign_date),
    requirementsCount: (vm) => vm.sourceData.requirements.length,
    correspondenceCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 2).length,
    discrepanciesCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 3).length,
    notApplicableCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 4).length,
    computedTableData() {
      return this.tableData?.map((item) => {
        return {
          requirements: item?.requirement?.text,
          discrepancy: item?.commentary,
          events: `${item?.action?.text || ''}. ${item?.action?.plan_date ? 'Срок: ' + moment(Number(item?.action?.plan_date)).format('DD.MM.YYYY') : ''}`,
          responsible: item?.action?.responsible?.name
        }
      })
    }
  },
  methods: {
    toPercent(to, from) {
      return Math.round((100 / from) * to)
    }
  }
}
</script>

<style lang="scss">
.checklist-report-template {
  display: flex;
  flex-direction: column;

  &__order-box {
    padding: 1rem;
    border: 1px solid #000;
  }
}
</style>
