import { ref } from 'vue'
import { defineStore } from 'pinia'
import { uniqBy } from 'lodash'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'
import * as vuexStore from './index'

export const useIncidentsStore = defineStore('incidents', () => {
  const endpoint = 'inevent/'

  const message = useMessage()
  const notify = useNotification()

  const isLoading = ref(true)
  const isSaving = ref(false)
  const isFetching = ref(false)
  const isDeleting = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const list = ref([])
  const item = ref(null)
  const files = ref([])
  // const page = ref(1)

  const fetch = async ({ risk_id, date_start = '', date_end = ''}, isFetchMore = false) => {
    try {
      // if (isFetchMore) {
      //   page.value += 1
      // } else {
      //   page.value = 1
      // }

      const url = `${endpoint}?${risk_id ? `risk_id=${risk_id}&` : ''}${date_start ? `date_start=${date_start}&` : ''}${date_end ? `date_end=${date_end}&` : ''}`
      const res = await api.get(url)

      if (res.status === 200 && res.data.length) {
        // if (isFetchMore) {
        //   const data = [
        //     ...list.value,
        //     ...res.data,
        //   ]
        //   list.value = uniqBy(data, 'id') 
        // } else {
        //   list.value = res.data
        // }
        list.value = res.data
      }

      return res
    } catch (error) {
      notify.error({
        title: 'Не удалось получить список',
        duration: 2500
      })
    }
  }

  const fetchOne = async (id, showLoading = true) => {
    try {
      isLoading.value = showLoading
      isFetching.value = true
      isError.value = false
      error.value = null

      const res = await api.get(`${endpoint}get`, {
        params: {
          inevent_id: id
        }
      })
      // const statusRes = await getStatus(id)

      if (res.data.id) {
        item.value = res.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
    } finally {
      isLoading.value = false
      isFetching.value = false
    }
  }

  const create = async (payload, showMessage = true) => {
    try {
      isSaving.value = true

      const res = await api.post(endpoint, payload)

      if (res.data.id) {
        message.success('Нежелательное событие зарегистрировано')
        return res.data
      }

      if (res.data.errors) {
        notify.error({
          title: 'Произошла ошибка!',
          content: JSON.stringify(res.data.errors),
          duration: 3000
        })
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось создать',
        duration: 3000
      })
      console.log(e)
    } finally {
      isSaving.value = false
    }
  }

  const update = async (id, payload) => {
    try {
      isSaving.value = true

      const res = await api.put(`${endpoint}edit?inevent_id=${id}`, payload)

      await fetchOne(id, false)
      message.success('Статус обновлен')

      if (res.status === 200) {
        message.success('Обновлено')
        return res
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось обновить',
        duration: 2500
      })
    } finally {
      isSaving.value = false
    }
  }

  const deleteById = async (id) => {
    try {
      isDeleting.value = true

      // const res = await api.post(endpoint + 'delete', { id })
      const res = await api.delete(`${endpoint}delete?inevent_id=${id}`)

      message.success('Удалено')
      list.value = list.value.filter((item) => item.id !== id)
      item.value = null

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  const getStatus = async (id) => {
    try {
      const res = await api.get('/event-state-machine/status', { params: { id } })
      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось получить статус мероприятия',
        duration: 2500
      })
    }
  }

  const updateStatus = async (id, status_id) => {
    try {
      const res = await api.put(`${endpoint}edit?inevent_id=${id}`, {
        status: status_id
      })

      await fetchOne(id, false)
      message.success('Статус обновлен')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось изменить статус',
        duration: 2500
      })
    }
  }

  const fetchDocs = async (id) => {
    try {
      const url = `/documents/get-by-model?model_name=inevent&model_id=${id}`
      const response = await api.get(url)
      console.log('responseresponseresponse', response);
      
      try {
        if (response.status === 200) {
          const { documentsCategory } = vuexStore.getters

          const normalizedData = response.data.data.map((doc) => ({
            ...doc,
            uploaded_at: doc.uploaded_at * 1000,
            category: documentsCategory.find((docCategory) => docCategory.id === doc.category_id)
          }))

          files.value = normalizedData
          // context.commit('set_inspection_files', normalizedData)
        }
      } catch (error) {
        console.warn(`INSPECTION_GET_DOCUMENTS: ${error}`)
      }
    } catch (error) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось загрузить документы',
        duration: 2500
      })
    }
  }

  const uploadDoc = async ({ id, formData }) => {
    const url = `inevent/upload-file?inevent_id=${id}`

    return api.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } 

  return {
    isLoading,
    isFetching,
    isDeleting,
    isError,
    error,
    list,
    item,
    files,
    fetch,
    fetchOne,
    create,
    update,
    deleteById,
    getStatus,
    updateStatus,
    fetchDocs,
    uploadDoc
  }
})
